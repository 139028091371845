import React from "react"
import { Map, Markers } from "react-amap"
import markerIconUrl from "../../assets/contacts/marker_plain.svg"

const style = {
  background: `url(${markerIconUrl})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
  width: "42px",
  height: "46px",
  color: "#000",
}

class AMap extends React.Component {
  constructor() {
    super()
    this.mapCenter = { longitude: 116.512302, latitude: 39.891178 }
    this.markers = [
      {
        position: { longitude: 116.512302, latitude: 39.891178 },
        label: "祥龙物流",
      },
    ]
  }

  renderMarkerLayout(extData) {
    return <div style={style} />
  }

  render() {
    return (
      <div style={{ width: "100%", height: "40vh" }}>
        <Map
          zoom={16}
          center={this.mapCenter}
          amapkey={"69fe7d4d1a6f69c03a947f96e06e2617"}
          version={"1.4.14"}
        >
          <Markers markers={this.markers} render={this.renderMarkerLayout} />
        </Map>
      </div>
    )
  }
}

export default AMap
