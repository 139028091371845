import React from "react"
import Container from "../components/container"
import Layout from "../components/layout"
import ContactWay from "../components/contactpage/contact-way"
import AMap from "../components/contactpage/amap"
// import ContactMap from "../components/contactpage/contact-map"

// document.writeln("<script type=\'text/javascript\' src=\'http://api.map.baidu.com/api?v=2.0&ak=3dkGzL5cVtzAiXz7Cyiny264KrOhwB8G\'></script>");
// document.writeln("");

class Contact extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Container>
          <h2>北京祥龙物流（集团）有限公司</h2>
          <h3>联系方式</h3>

          <ContactWay />
          <AMap />

          {/* <ContactMap /> */}
        </Container>
      </Layout>
    )
  }
}
export default Contact
