import React from "react"
import styled from "react-emotion"
import {
  AddIcon,
  PhoneIcon,
  FaxIcon,
  EmailIcon,
  MapIcon,
} from "../../assets/contacts"
import presets, { colors } from "../../utils/presets"
import { rhythm } from "../../utils/typography"

const SubSection = styled(`section`)`
  margin: ${rhythm(presets.gutters.default / 2)};
  padding: ${rhythm(presets.gutters.default / 2)};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  ${presets.Desktop} {
    flex-direction: column;
  }
`

const Item = styled(`div`)`
  width: 80%;
  max-width: ${rhythm(20)};
  margin: ${rhythm(presets.gutters.default / 2)};
`
const ICON_SIZE = `20px`

const Title = styled(`h5`)`
  display: flex;
  align-items: center;
  color: black;
  font-size: 1rem;
  margin: 0;
  // margin-left: calc(${ICON_SIZE} * -1.2);
`

const Icon = styled(`span`)`
  display: block;

  margin-right: calc(${ICON_SIZE} / 5);

  svg {
    fill: black;
    height: ${ICON_SIZE};
    stroke: ${props => (props.inverse ? colors.ui.light : colors.lilac)};
    width: ${ICON_SIZE};
  }
`
const ItemTitle = ({ icon, children }) => (
  <Title inverse={true}>
    {icon && <Icon dangerouslySetInnerHTML={{ __html: icon }} inverse={true} />}
    {children}
  </Title>
)

const ItemDescription = styled(`p`)`
  color: black;
  display: block;
  margin-left: calc(${ICON_SIZE} * 1.2);
`

const ContactWay = () => {
  return (
    <SubSection>
      <Item>
        <ItemTitle icon={AddIcon}>　地址</ItemTitle>
        <ItemDescription>　北京市朝阳区广渠东路2号</ItemDescription>

        <ItemTitle icon={PhoneIcon}>　电话</ItemTitle>
        <ItemDescription>　010-6771 0797</ItemDescription>

        <ItemTitle icon={FaxIcon}>　传真</ItemTitle>
        <ItemDescription>　010-6771 0793 　010-6778 2680</ItemDescription>

        <ItemTitle icon={EmailIcon}>　电子邮件</ItemTitle>
        <ItemDescription>　xllg@xllg.com</ItemDescription>

        <ItemTitle icon={MapIcon}>　地图</ItemTitle>
      </Item>
    </SubSection>
  )
}

export default ContactWay
